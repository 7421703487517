
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        PageTitle: () => import("./parts/general/page-title.vue"),
        ContactHeaderPart: () => import("@/views/seller/parts/contact/contact-header.vue"),
        ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
        SharedSellers: () => import("@/views/seller/parts/shared-sellers.vue"),
        PageSlider: () => import("./parts/general/page-slider.vue"),
        contactCardNew: () => import("@/views/seller/parts/contact/contact-card-new.vue"),
    },
})
export default class PageSellerContacts extends Vue {
    selected: ActivityAssignedTo | null = null;

    handleSelected(member: ActivityAssignedTo) {
        this.selected = member;
    }
}
